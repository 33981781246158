<template>
  <v-container fluid>
    <v-toolbar
      color="transparent"
      class="my-4"
      :dark="this.$store.state.darkTheme ? true : false"
    >
      <v-toolbar-title>
        <h1
          v-if="!isMobile()"
          class="font-weight-light"
        >
          Frequently asked questions
        </h1>
        <h2
          v-if="isMobile()"
          class="font-weight-light"
        >
          FAQ
        </h2>
      </v-toolbar-title>
    </v-toolbar>

    <v-row align="center">
      <v-expansion-panels
        popout
        :dark="this.$store.state.darkTheme? true : false"
      >
        <v-expansion-panel :style="this.$store.state.darkTheme? styleDark : styleLight ">
          <v-expansion-panel-header>What is PERSEUS?</v-expansion-panel-header>
          <v-expansion-panel-content>
            Perseus is a web portal designed for users and administrators of GRICAD.
            <br>Its goal is to give a entry point to all users of GRICAD's installations.
            <br>
            <br>
            <p>
              It offers many functionnalities such as :
              <br>> Account creation and status :
              Users can create an account to access GRICAD services and check their authorisation status.
              <br>> Project Management :
              To access clusters, users have to join an active project or create and manage a project.
              <br>> Project review :
              Project admins interact with Gricad reviewers to create a project and verify its validity every year. It means that the HPC activity is well known by every person concerned.
              <br>> Compatibility :
              The users can easily access to accounting informations relative to their activity, such as the computing power used (hours use oar each cluster) by them and also or their project.
              <br>> Management of diffusion lists :
              Fast activate / desactivate of diffusion link to HPC.
              <br>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel :style="this.$store.state.darkTheme? styleDark : styleLight ">
          <v-expansion-panel-header>What does it mean when my account is "pending"?</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>The "pending" status means that your account is waiting to be activated.</p>
            <p>Be sure to have requested to join an active project. Otherwise your account cannot be activated.</p>
            <p>
              <v-btn
                :dark="this.$store.state.darkTheme? true : false"
                to="/project"
                color="primary"
              >
                Go the projects page &nbsp;
                <v-icon medium>
                  fas fa-angle-right
                </v-icon>
              </v-btn>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel :style="this.$store.state.darkTheme? styleDark : styleLight ">
          <v-expansion-panel-header>My contract is extended. What do I need to do to get my PERSEUS account extented too?</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>Contact the support team giving the new expiry date and your Perseus login. They will extend your account.</p>
            <v-btn
              :dark="this.$store.state.darkTheme? true : false"
              to="/contact"
              color="primary"
            >
              Contact the support &nbsp;
              <v-icon medium>
                fas fa-angle-right
              </v-icon>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel :style="this.$store.state.darkTheme? styleDark : styleLight ">
          <v-expansion-panel-header>My account is going to expire but I'm a permanent faculty member. Why?</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>The expiration date of a PERSEUS account depends also on the last validity date of the projects which you are a member of.</p>
            <p>So if your account is permanent and your account is going to expire, that means that all your projects are coming to their end.</p>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel :style="this.$store.state.darkTheme? styleDark : styleLight ">
          <v-expansion-panel-header>What is the life cycle of a project?</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>The projects are created by the permanent members of laboratories who have signed the GRICAD charter.</p>
            <p>When the project is created, it's up to the project administrator to add, and accept or not the new members to the project.</p>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel :style="this.$store.state.darkTheme? styleDark : styleLight ">
          <v-expansion-panel-header>How to join an existing project?</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>If you are member of a team, there can already be a project for your research subject.</p>
            <p>Ask the person in charge of your team.</p>
            <p>
              If thats so, go to your PERSEUS account :
              <br>> Go to your tab
              <i>My Projects</i>,
              <br>> Click on join a project, write the name of the wanted project. And
              <br>> Click on
              <i>join</i>.
            </p>
            <p>The projects' admin will be informed of your request and will add you to his project if he agrees.</p>
            <p>You will be warned by e-mail when your request is accepted.</p>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          v-if="permanent()"
          :style="this.$store.state.darkTheme? styleDark : styleLight"
        >
          <v-expansion-panel-header>How to create a project?</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              It is a very important step of the process.
              You or your project manager have to give a minimum amount of informations on what you're planning to do with Gricad's services.
              All projects are verified on a yearly basis by the Gricad team.
              There is no time attribution or proposal call mechanism to determine access to the resources.
              Projects are central for ressources management via fair sharing configuration on computing platforms.
            </p>
            <p>If you know your project targets a specific platform (Openstack, Dahu, Luke , ...), please specify it in the "technical description".</p>
            <p>
              Proceed as follow :
              <br>> Create a project by clicking on the link
              <i>Ask a new project creation</i> in the section
              <i>My projects</i>.
              <br>> You will be
              <i>in charge</i> of this project.
              If it's not the case, ask your project manager to create a Perseus account and create the project themself, even if they are not going to use the clusters.
              <br>> Please, be as precise as possible when filling in the form
              <br>> Once submited, the pole leader will be informed and they will review your project within the best delays.
              <br>> You can check if your project is in the section
              <i>My projects</i> of Perseus.
              <br>> You and the reviewers can comment or modify the form.
              You will be warned each time a comment has been added to your project.
              <br>> Once the reviewers have accepted your project, its status will change from 
              <i>Pending</i> to
              <i>Active</i> and you will be warned by e-mail.
              <br>> At every step, you can add new members to this project (you don't have to wait for the project to be active)
              <br>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <br><br><br>
  </v-container>
</template>

<script>
  import config from '@/config'

  export default {
    data: () => ({
      fontsize: 18,
      green: config.colors.color_ok,
      red: config.colors.color_not,
      blockDark: config.colors.card_bg_dark,
      blockLight: config.colors.card_bg_light,
      redg: config.colors.g_red,
      dblueg: config.colors.g_darkblue,
      lblueg: config.colors.g_lightblue,
    }),

    computed: {
      styleDark () {
        return {
          'background-color': this.blockDark,
          color: 'white',
        }
      },
      styleLight () {
        return {
          'background-color': this.blockLight,
          color: 'black',
        }
      },
    },

    methods: {
      isMobile () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
      permanent () {
        return this.$store.state.permanent
      },
      gotop () {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      },
    },

  }
</script>

<style lang="scss">

  .v-expansion-panel-header {
    font-size: 20px;
  }

  .v-expansion-panel--active {
    margin: 1em;
    font-size: 16px;
  }
</style>
